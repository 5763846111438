<template lang="html">
  <div class="heading-and-description">
    <div class="heading-and-description__heading" v-if="initialData.heading">
      <Heading :text="initialData.heading" :hType="1" textAlign="center" />
    </div>
    <div
      class="heading-and-description__description"
      v-if="initialData.description"
      v-html="initialData.description"
    ></div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'HeadingAndDescription',
  components: { Heading },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
